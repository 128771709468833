import React from "react"
import classnames from "classnames"
import "./Button.scss"

export default function Button(props) {
  const {
    className = "",
    inline,
    importance = "secondary",
    incognito,
    ...rest
  } = props

  const classNames = classnames({
    button: true,
    inline: inline,
    [importance]: importance,
    incognito: incognito,
    [className]: className,
  })
  return <button {...rest} className={classNames} />
}

import React from "react"
import { CZaasThemeContext, localThemes } from "../layouts"
import Button from "./Button/Button"
import { Grid, Column } from "./Grid/Grid"
import "./ThemeManager.scss"
import { Link } from "gatsby"

export default function ThemeManager() {
  const {
    activeTheme,
    setActiveTheme,
    themeStore,
    deleteTheme,
  } = React.useContext(CZaasThemeContext)

  function handleChangeTheme(e) {
    e.preventDefault()
    setActiveTheme(e.target.id)
  }
  function handleDeleteTheme(e) {
    e.preventDefault()
    deleteTheme(e.target.dataset.id)
  }

  return (
    <Grid gutter="16">
      {Object.keys(themeStore).map(id => {
        const themeIsCustom = !localThemes.find(t => t.id === id)
        return (
          <Column
            key={id}
            base="1-2"
            lg="1-4"
            xl="1-6"
            className="themeManager"
          >
            <Button onClick={handleChangeTheme} id={id} incognito inline>
              {themeStore[id]}
            </Button>
            <div className="themeManager__actions">
              {themeIsCustom && (
                <div className="">
                  [
                  <a onClick={handleDeleteTheme} data-id={id}>
                    Delete
                  </a>
                  ] [<Link to={`/themes/edit?theme-id=${id}`}>Edit</Link>]
                </div>
              )}
            </div>
          </Column>
        )
      })}
    </Grid>
  )
}

import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import ThemeManager from "../../components/ThemeManager";
import { navigate } from "gatsby";
import { Grid, Column } from "../../components/Grid/Grid";
import Button from "../../components/Button/Button";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Choose your theme`}</h1>
    <p>{`Apply a theme`}</p>
    <ThemeManager mdxType="ThemeManager" />
    <p>{`or`}</p>
    <Grid gutter={"32"} mdxType="Grid">
  <Column mdxType="Column">
    <Button inline importance="primary" onClick={() => navigate("/themes/create")} mdxType="Button">
      Create A Theme
    </Button>
  </Column>
    </Grid>
    <h2>{`Philosophy`}</h2>
    <p>{`Understand how the colors relate and how to add new components. I will abbreviate "foreground" as "fg" and "background" as "bg". Currently there is only one primary color.`}</p>
    <h3>{`How do the colors relate`}</h3>
    <p>{`Foreground and background should have a high contrast ratio. Both in lightness and color vision in mind. You will get a message of`}</p>
    <h2>{`What is this?`}</h2>
    <p>{`Theme managed with CSS variables. Components are prebuilt to consume these variables.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      